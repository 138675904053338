<template>
    <div class="add_address_box">
        <div class="address_main">
            <i class="iconfont icon-icon_x close_icon" @click="cancel()"></i>

            <div class="address_title">收货地址</div>

            <el-form :model="form" :rules="rules" ref="form_address" label-width="100px">
                <el-form-item label="收货人:" prop="name">
                    <el-input v-model="form.name" maxlength="18"></el-input>
                </el-form-item>

                <el-form-item label="所在地区:" prop="district">
                    <el-cascader
                        v-model="form.district"
                        :options="options"
                        :props="e_props"
                        @change="handleChange"
                    ></el-cascader>
                </el-form-item>

                <el-form-item label="详细地址:" prop="address">
                    <el-input v-model="form.address"></el-input>
                </el-form-item>

                <el-form-item label="手机/固话:" prop="tel">
                    <el-input v-model="form.tel"></el-input>
                </el-form-item>

                <el-form-item label="邮政编码:">
                    <el-input v-model="form.postcode"></el-input>
                </el-form-item>

                <div class="confirm_check">
                    <div class="arrdess_checkbox">
                        <input
                            type="checkbox"
                            class="default_arrdess"
                            v-model="default_check"
                            />
                            设置为默认收货地址
                    </div>
                </div>

                <div class="confirm_btns">
                    <el-button @click="cancel">取消</el-button>
                    <el-button class="sure" type="primary" @click="submitForm()">确定</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import dataCity from "@/assets/city";
import { postData, putData } from "@/api/user";
import { ElMessage } from "element-plus";
import {toRefs, reactive,defineComponent,ref } from 'vue'


export default defineComponent({
    name: "add_address",
    props:{
        type:String,
        info:String
    },
    emits:['changeModal'],
    setup (props,ctx) {
        const adr_type =reactive(props.type)
        const adr_info = reactive(props.info)
        const form_address = ref(null)

        let checkFixTel = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("请输入手机/固话"));
            }
            var mobile = /^1[345789]\d{9}$/,
                phone = /^0\d{2,3}-?\d{7,8}$/;
            if (mobile.test(value) || phone.test(value)) {
                callback();
            } else {
                return callback(new Error("请正确输入手机号/固话！"));
            }
        };

        let state = reactive({
            options: dataCity,
            e_props: {
                expandTrigger: "hover",
                value: "value",
                label: "label",
                children: "children",
            },
            default_check: true,
            form: {
                name: "",
                district: [], // ['江苏', '南京', '江宁区']
                area:"", // 江苏 南京 江宁区
                address: "",
                tel: "",
                postcode: "",
            },
            rules: {
                name: [{ required: true, message: "请输入收货人", trigger: "blur" }],
                district: [{ required: true, message: "请选择所在地区", trigger: "change" },],
                address: [{ required: true, message: "请输入详细地址", trigger: "blur" },],
                tel: [{ required: true, validator: checkFixTel, trigger: "blur" }],
            },
        })

        let init = () => {
            if (adr_info && adr_type == "edit") {
                state.form = adr_info;
                state.default_check = adr_info.default == "是" ? true : false;
                setTimeout(() => {
                    state.form.area = adr_info.district
                    state.form.district = adr_info.district ? adr_info.district.split('/') : [];
                }, 100);
            }
        }
        init()

        let handleChange = (value) => {
            state.form.area = `${value[0]}/${value[1]}/${value[2]}`
        }

        let submitForm = () => {
            form_address.value.validate((valid) => {
                if (valid) {
                    let params = {
                        name: state.form.name,
                        district: state.form.area,
                        address: state.form.address,
                        tel: state.form.tel,
                        postcode: state.form.postcode,
                        default: state.default_check ? "是" : "否",
                    };
                    if (adr_type == "edit") {
                        putData({
                            url: `api/my_home/my_address/${state.form.id}`,
                            data: params,
                        }).then((res) => {
                            let data = res.data;
                            if (data.code === 200) {
                                ElMessage.success(data.data.msg);
                                cancel();
                            } else if (data.code === 422) {
                                ElMessage.error(data.data.msg);
                            }
                        });
                    } else {
                        postData({
                            url: `api/my_home/my_address`,
                            data: params,
                        }).then((res) => {
                            let data = res.data;
                            if (data.code === 200) {
                                ElMessage.success(data.data.msg);
                                cancel();
                            } else if (data.code === 422) {
                                ElMessage.error(data.data.msg);
                            }
                        });
                    }
                } else {
                    return false;
                }
            });
        }

        let cancel = () => {
            ctx.emit("changeModal", false);
        }

        return {
            ...toRefs(state),
            adr_type,
            adr_info,
            submitForm,
            cancel,
            handleChange,
            form_address
        }
        
    }
})
</script>

<style lang="less" scoped>
input[type="checkbox"] {
    margin: 0 12px 0 0;
    cursor: pointer;
    position: relative;
    width: 15px!important;
    height: 15px!important;

    font-size: 14px;
}

input[type="checkbox"]::after {
    position: absolute;
    top: 0;
    color: #fff;
    background-color: #fff;
    width: 17px;
    height: 17px;
    display: inline-block;
    visibility: visible;
    text-align: center;
    content: " ";
    border: 1px solid #d1d1d1;
}

input[type="checkbox"]:checked::after {
    content: "✓";
    font-size: 12px;
    font-weight: bold;
    background-color: #1C46B7;
}
</style>
<style lang="less">
.add_address_box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 998;

    .address_main {
        position: relative;
        padding: 0 24px;
        border-radius: 8px;
        width: 433px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);

        .close_icon {
            position: absolute;
            top: 14px;
            right: 16px;
            font-size: 10px;
            color: #666;
            cursor: pointer;
        }

        .address_title {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: #333333;
            padding: 18px 0 24px;
        }
    }
    .el-form-item {
        margin-bottom: 20px;

        .el-form-item__label {
            color: #666;
            font-size: 13px;
            text-align: right;
            padding-right: 16px;
        }
        
        .el-input__inner {
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #D1D1D1;
            border-radius: none!important;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
            padding: 7px 16px;
        }
        .el-select,.el-cascader {
            width: 100%;
        }
        .el-textarea__inner {
            width: 294px;
            height: 79px;
            background: #FFFFFF;
            border: 1px solid #D1D1D1;
            resize: none;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
            padding: 7px 16px;
        }
    }

    .confirm_check {
        padding-left: 100px;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        color: #333333;
    }
    .confirm_btns {
        text-align: right;
        padding: 18px 0;

        .el-button {
            width: 68px;
            height: 32px;
            border: 1px solid #ddd;
            border-radius: 3px;
            font-size: 14px;
            line-height: 32px;
            color: #666666;
            padding: 0;
            background: #FAFAFA;

        }
        .sure {
            background: #1C46B7;
            color: #fff;
            border: 1px solid #1C46B7;
        }
    }
}


</style>

